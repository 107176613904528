/**
 * @internal
 * Helper for generating analytics params. Exported for tests */
export function getGAParams(searchParams = "") {
  const params = new URLSearchParams(searchParams);
  return {
    utm_source: params.get("utm_source") || "none",
    utm_campaign: params.get("utm_campaign") || "none",
    utm_medium: params.get("utm_medium") || "none",
    wallet_id: "",
  };
}

/** Send a google analytics eventc */
export function sendGoogleTagEvent(event: HSGTagEvent, urlSearchParams = "") {
  const eventParams = getGAParams(urlSearchParams);

  // @ts-ignore
  window.gtag("event", event, eventParams);
}

export type HSGTagEvent =
  | "LAUNCH_APP"
  | "LAUNCH_APP-Defi_Cover"
  | "LAUNCH_APP-Navigation_Bar"
  | "LAUNCH_APP-Humble_Products"
  | "LAUNCH_APP-Why_Humble"
  | "LAUNCH_APP-Humble_Photo_Phrame"
  | "LAUNCH_APP-Form";

import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import Tape, { TapeType } from "components/Tape";
import { useIsMobile } from "utils/useScreenSize";
import lineBg from "../../assets/cross-chain/line-bg.svg";

const Container = styled(PageContainer)`
  overflow: hidden;
`;

const TapeContainer = styled.div`
  height: 20vw;
  @media screen and (max-width: 1240px) {
    height: 40vw;
  }
`;

const MobileTapes = styled.div`
  height: 140vw;
`;

const TextWrapper = styled.div`
  background-image: url(${lineBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 56%;
  max-width: 957px;
  align-self: flex-start;
  margin-top: 170px;
  padding: 70px 0 165px 8%;
  @media screen and (max-width: 1440px) {
    width: 62%;
    margin-top: 105px;
  }
  @media screen and (max-width: 1240px) {
    width: 65%;
  }
  @media screen and (max-width: 1124px) {
    width: 67%;
    padding: 70px 0 115px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 96.5%;
    padding: 47px 0 65px 16px;
  }
`;

const Title = styled.div`
  width: 457px;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
  text-align: left;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 36px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 32px;
    line-height: 24px;
  }
`;

const Description = styled.div`
  width: 457px;
  font-family: "Prompt", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: white;
  text-align: left;
  margin-top: 40px;
  @media screen and (max-width: 1024px) {
    width: 70%;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 480px) {
    width: 68.8%;
    margin-top: 28px;
    font-size: 18px;
    line-height: 20px;
  }
`;

function CrossChain() {
  const isMobile = useIsMobile();

  return (
    <Container className="primary">
      <TextWrapper>
        <Title className="secondary--text">Cross-chain solutions</Title>
        <Description>
          Starting with Algorand, we’ll be taking the future of finance on tour.
          <br />
          <br />
          Check back often for announcements of where we’ll go next.
        </Description>
      </TextWrapper>
      {isMobile ? (
        <MobileTapes>
          <Tape
            type={TapeType.TAPE_SOLANA}
            angle={12.71}
            offsetMargin={-20}
            tabOffsetTop={50}
          />
          <Tape
            type={TapeType.TAPE_ALGORAND}
            angle={-35.29}
            offsetMargin={-30}
            rtl
            tabOffsetTop={40}
          />
          <Tape
            type={TapeType.TAPE_ETHEREUM}
            angle={-10.29}
            offsetMargin={-20}
            tabOffsetTop={71}
          />
        </MobileTapes>
      ) : (
        <>
          <Tape type={TapeType.TAPE_SOLANA} angle={44.71} offsetMargin={-30} />
          <Tape
            type={TapeType.TAPE_ALGORAND}
            angle={-52.81}
            offsetMargin={-10}
            rtl
            tabOffsetTop={30}
          />
          <TapeContainer>
            <Tape
              type={TapeType.TAPE_ETHEREUM}
              angle={-15.29}
              offsetMargin={-55}
              tabOffsetTop={12}
            />
          </TapeContainer>
        </>
      )}
    </Container>
  );
}

export default CrossChain;

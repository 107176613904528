import styled from "styled-components";
import { sendGoogleTagEvent } from "utils/googleAnalytics";
import { ButtonLink } from "./Forms/Button";
import { AllButtonLinkProps } from "./Forms/Button.Helpers";

const LaunchAppButton = styled((props: AllButtonLinkProps) => (
  <ButtonLink
    {...props}
    onClick={() =>
      sendGoogleTagEvent(props.tagAction || "LAUNCH_APP", props.location || "")
    }
  >
    Launch<span> App</span>
  </ButtonLink>
)).attrs({
  href: "https://app.humble.sh",
  target: "_blank",
  rel: "noopener noreferrer",
  variant: "dark",
})<AllButtonLinkProps>`
  font-family: "Prompt", sans-serif;
  font-size: 18px;
  font-weight: 500;
  border-radius: 20px;
  white-space: pre;
  @media screen and (max-width: 576px) {
    span {
      display: none;
    }
  }
`;

export default LaunchAppButton;

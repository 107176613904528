import { forwardRef } from "react";
import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import SocialIcon from "components/common/SocialIcon";
import THEME from "theme";
import Bees from "assets/community/bees.svg";
import HumbleImg from "assets/community/humble.svg";
import { ReactComponent as IconDiscord } from "assets/social/black-md-discord.svg";
import { ReactComponent as IconTwitter } from "assets/social/black-md-twitter.svg";
import { ReactComponent as IconTelegram } from "assets/social/black-md-telegram.svg";
import { ReactComponent as IconReddit } from "assets/social/black-md-reddit.svg";
import { ReactComponent as IconFrameGenerator } from "assets/social/black-md-frame-generator.svg";
import {
  HUMBLE_SWAP_FEATURE_REQUEST,
  HUMBLE_SWAP_REPORT_BUG,
} from "constants/links";

const Container = styled(PageContainer)`
  padding-top: 150px;
  text-align: left;
`;

const WidthWrapper = styled.div`
  max-width: 1128px;
  @media screen and (max-width: 1124px) {
    max-width: 780px;
  }
  @media screen and (max-width: 480px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const BeesImage = styled.img`
  width: 150px;
  height: 150px;
  @media screen and (max-width: 1124px) {
    margin-left: -3%;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`;

const Header = styled.div`
  width: 360px;
  @media screen and (max-width: 1124px) {
    width: auto;
    max-width: 780px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
  }
  @media screen and (max-width: 480px) {
    max-width: 250px;
  }
`;

const Description = styled.div`
  margin-top: 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1124px) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 684px;
  margin-top: -60px;
  @media screen and (max-width: 1124px) {
    width: 100%;
    max-width: unset;
    margin-top: 0;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Socials = styled.div`
  max-width: 316px;
  margin-top: 28px;
  @media screen and (max-width: 1124px) {
    max-width: 300px;
  }
  @media screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const Social = styled.div`
  display: flex;
  margin-top: 32px;
`;

const SocialTexts = styled.div`
  max-width: 260px;
  min-width: 244px;
  margin-left: 16px;
  @media screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const TextTitle = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
`;

const TextDetail = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
`;

const Details = styled.div`
  margin-top: 28px;
`;

const Detail = styled.div`
  margin-top: 32px;
  max-width: 260px;
  @media screen and (max-width: 1124px) {
    max-width: 300px;
  }
  @media screen and (max-width: 768px) {
    max-width: unset;
  }
`;

const DetailHumble = styled.img`
  width: 300px;
  height: 300px;
  @media screen and (max-width: 1124px) {
    margin-top: 32px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const socials = [
  {
    icon: IconDiscord,
    name: "Discord",
    detail: "Come chat with other users and team members.",
    link: "https://discord.gg/wG3wtv7qn6",
  },
  {
    icon: IconTwitter,
    name: "Twitter",
    detail: "Product updates, contests, and the occasional meme.",
    link: "https://twitter.com/humbledefi",
  },
  {
    icon: IconTelegram,
    name: "Telegram",
    detail: "Stay up to date.",
    link: "https://t.co/ARPI9rxcFP",
  },
  {
    icon: IconReddit,
    name: "Reddit",
    detail:
      "Join our subreddit today for AMAs, announcements, and other fun events.",
    link: "https://www.reddit.com/r/HumbleDefi/",
  },
  {
    icon: IconFrameGenerator,
    name: "Frame Generator",
    detail: "Customize your profile picture with our awesome frames.",
    link: "/hpf",
  },
];

const details = [
  {
    title: "Suggest a feature",
    content:
      "Help build the future. We can’t add an ice cream dispenser; we already asked.",
    link: HUMBLE_SWAP_FEATURE_REQUEST,
  },
  {
    title: "Bug Report",
    content: "If you see something, say something.",
    link: HUMBLE_SWAP_REPORT_BUG,
  },
];

const Community = forwardRef<HTMLDivElement>((_props, ref) => (
  <div ref={ref}>
    <Container className="secondary responsive-width">
      <WidthWrapper>
        <BeesImage src={Bees} />
        <ContentWrapper>
          <Header>
            <Title>The Humble community</Title>
            <Description>
              We’re better together. Follow us on Twitter, join us in Discord,
              or submit a feature request. No matter how you choose to
              participate, we’d love to have you.
            </Description>
          </Header>
          <Content>
            <Socials>
              {socials.map((social, index) => (
                <Social key={`${index}-${social.name}`}>
                  <SocialIcon
                    icon={social.icon}
                    href={social.link}
                    hoverColor={THEME.colors.darkGray}
                    size={40}
                  />
                  <SocialTexts>
                    <TextTitle>{social.name}</TextTitle>
                    <TextDetail>{social.detail}</TextDetail>
                  </SocialTexts>
                </Social>
              ))}
            </Socials>
            <Details>
              {details.map((detail, index) => (
                <Detail key={`${index}-${detail.title}`}>
                  <TextTitle href={`${detail.link}`}>{detail.title}</TextTitle>
                  <TextDetail>{detail.content}</TextDetail>
                </Detail>
              ))}
              <DetailHumble src={HumbleImg} />
            </Details>
          </Content>
        </ContentWrapper>
      </WidthWrapper>
    </Container>
  </div>
));

Community.displayName = "Community";

export default Community;

import styled from "styled-components";
import algorandLogo from "assets/trusted-by-best/algorand-logo.png";
import arringtonLogo from "assets/trusted-by-best/arrington-logo.png";
import borderlessCapital from "assets/trusted-by-best/borderlessCapital.png";
import xBackedLogo from "assets/trusted-by-best/xBacked-logo.png";
import meldVenturesLogo from "assets/trusted-by-best/meldVentures-logo.png";
import jumpTradingLogo from "assets/trusted-by-best/jumpTrading-logo.png";
import reachLogo from "assets/trusted-by-best/reach-logo.png";
import cryptoLogo from "assets/trusted-by-best/crypto-logo.png";
import bigBrainLogo from "assets/trusted-by-best/big-brain-logo.png";

type Partner = {
  image: string;
  altText: string;
  padding?: number;
};

const PARTNERS = {
  borderless: {
    image: borderlessCapital,
    altText: "Borderless Capital",
    padding: 10,
  },
  bigBrain: {
    image: bigBrainLogo,
    altText: "Big Brain Holdings",
    padding: 20,
  },
  reach: { image: reachLogo, altText: "Reach, Algorand, Crypto" },
  algorand: { image: algorandLogo, altText: "Partners" },
  rCrypto: { image: cryptoLogo, altText: "R Crypto" },
  arrington: {
    image: arringtonLogo,
    altText: "Arrington XRP Capital",
  },
  xBacked: { image: xBackedLogo, altText: "xBacked" },
  meldVentures: { image: meldVenturesLogo, altText: "Meld Ventures" },
  jumpTrading: { image: jumpTradingLogo, altText: "Jump Trading" },
};

const Partners = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5em;
  padding: 20px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    padding: 20px 0 40px 0;
  }
`;

const LogoBox = styled.div`
  align-items: center;
  background: #36332e;
  display: flex;
  height: 200px;
  width: 200px;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  @media only screen and (max-width: 600px) {
    width: 160px;
    height: 160px;
    img {
      transform: scale(0.8);
    }
  }
`;

const LogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

function PartnerLogo({ partner }: { partner: Partner }) {
  const { image, altText, padding = 0 } = partner;
  return (
    <LogoBox style={{ padding: `${padding}px` }}>
      <LogoImage src={image} alt={altText} />
    </LogoBox>
  );
}

export default function PartnersComponent() {
  const {
    borderless,
    bigBrain,
    reach,
    algorand,
    rCrypto,
    arrington,
    xBacked,
    meldVentures,
    jumpTrading,
  } = PARTNERS;
  return (
    <Partners>
      <PartnerLogo partner={borderless} />
      <PartnerLogo partner={bigBrain} />
      <PartnerLogo partner={reach} />
      <PartnerLogo partner={algorand} />
      <PartnerLogo partner={rCrypto} />
      <PartnerLogo partner={arrington} />
      <PartnerLogo partner={xBacked} />
      <PartnerLogo partner={meldVentures} />
      <PartnerLogo partner={jumpTrading} />
    </Partners>
  );
}

import { useEffect } from "react";

function HubspotContactForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: "21135646",
          formId: "6307175d-c99d-4cf4-88a4-ff380e18d664",
          target: "#hubspotForm",
          cssClass: "hs-form-custom",
          cssRequired: "",
          submitButtonClass: "hs-form-custom-button",
        });
      }
    });
  }, []);

  return <div id="hubspotForm" />;
}

export default HubspotContactForm;

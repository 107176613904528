import styled from "styled-components";
import SocialIcon from "./common/SocialIcon";
import THEME from "theme";
import { ReactComponent as Discord } from "assets/social/discord.svg";
import { ReactComponent as Twitter } from "assets/social/twitter.svg";
import { ReactComponent as Telegram } from "assets/social/telegram.svg";
import { ReactComponent as Reddit } from "assets/social/reddit.svg";

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export default function SocialLinks() {
  return (
    <FlexRow>
      <SocialIcon
        icon={Twitter}
        href="https://twitter.com/humbledefi"
        hoverColor={THEME.colors.orange}
        size={38}
      />
      <SocialIcon
        icon={Discord}
        href="https://discord.gg/wG3wtv7qn6"
        hoverColor={THEME.colors.orange}
        size={38}
      />
      <SocialIcon
        icon={Telegram}
        href="https://t.co/ARPI9rxcFP"
        hoverColor={THEME.colors.orange}
        size={38}
      />
      <SocialIcon
        icon={Reddit}
        href="https://www.reddit.com/r/HumbleDefi/"
        hoverColor={THEME.colors.orange}
        size={38}
      />
    </FlexRow>
  );
}

import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import THEME from "theme";
import PhotoFrame from "pages/PhotoFrame";
import Home from "pages/Home";
import ReportBug from "pages/ReportBug";
import FeatureRequest from "pages/FeatureRequest";
import BecomeAPartner from "pages/BecomeAPartner";

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <THEME.GLOBAL />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="hpf" element={<PhotoFrame />} />
          <Route path="report-a-bug" element={<ReportBug />} />
          <Route path="feature-request" element={<FeatureRequest />} />
  <Route path="become-a-partner" element={<BecomeAPartner />} /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

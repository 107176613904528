import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Footer from "components/Footer";
import AppHeader, { ScrollIdType } from "components/AppHeader";
import {
  Cover,
  Support,
  CrossChain,
  Reasons,
  Learn,
  Community,
  Team,
  Brands,
} from "pages/sections";

const AppContainer = styled.div`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
`;

function Home() {
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const refHash: { [key in ScrollIdType]: React.RefObject<HTMLDivElement> } = {
    app: useRef<HTMLDivElement>(null),
    products: useRef<HTMLDivElement>(null),
    learn: useRef<HTMLDivElement>(null),
    team: useRef<HTMLDivElement>(null),
    community: useRef<HTMLDivElement>(null),
    support: useRef<HTMLDivElement>(null),
    whyHumble: useRef<HTMLDivElement>(null),
  };

  const productsHorizRef = useRef<HTMLDivElement>(null);
  const productsStickyRef = useRef<HTMLDivElement>(null);
  const supportHorizRef = useRef<HTMLDivElement>(null);
  const supportStickyRef = useRef<HTMLDivElement>(null);
  const supportCustomerRef = useRef<HTMLDivElement>(null);
  const supportCustTextRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleResize = () =>
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAppScroll = () => {
    // Animation for horizontal scrolling in Products section
    if (productsHorizRef.current && productsStickyRef.current) {
      productsHorizRef.current.style.transform = `translateX(-${productsStickyRef.current.offsetTop}px)`;
    }
    // Animation for horizontal scrolling in Support section
    if (supportHorizRef.current && supportStickyRef.current) {
      supportHorizRef.current.style.transform = `translateX(-${supportStickyRef.current.offsetTop}px)`;
    }
    if (supportCustomerRef.current && refHash.support.current) {
      const domRect = refHash.support.current.getBoundingClientRect();
      const translation = domRect.bottom - windowSize.height;
      // Animation for customer image moving up from the bottom of the page
      if (translation > 0) {
        supportCustomerRef.current.style.transform = `translateY(${translation}px)`;
      }
      // Animation for customer text showing up
      if (supportCustTextRef.current && translation < 0) {
        supportCustTextRef.current.classList.add("show");
      }
    }
  };

  return (
    <AppContainer onScroll={handleAppScroll} ref={refHash.app}>
      <AppHeader refHash={refHash} />
      <Cover />
      {/*
      <Support
        ref={refHash.support}
        horizRef={supportHorizRef}
        stickyRef={supportStickyRef}
        windowSize={windowSize}
        customerRef={supportCustomerRef}
        custTextRef={supportCustTextRef}
      />
      <CrossChain />
      <Reasons ref={refHash.whyHumble} />
      <Learn ref={refHash.learn} />
      <Community ref={refHash.community} />
      <Team ref={refHash.team} />
      <Brands />
      <Footer refHash={refHash} />
  */}
    </AppContainer>
  );
}

export default Home;

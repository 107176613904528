import { forwardRef } from "react";
import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import { useIsMobile } from "utils/useScreenSize";
import ReasonTile from "components/Reasons/ReasonTile";
import LaunchAppButton from "components/LaunchAppButton";
import BgReasonsLeft from "assets/reasons/bg-reasons-left.svg";
import BgReasonsRight from "assets/reasons/bg-reasons-right.svg";
import TileWarrior from "assets/reasons/tile-warrior.png";
import TileWarriorGif from "assets/reasons/tile-warrior.gif";
import TilePig from "assets/reasons/tile-pig.png";
import TilePigGif from "assets/reasons/tile-pig.gif";
import TileCoins from "assets/reasons/tile-coins.png";
import TileCoinsGif from "assets/reasons/tile-coins.gif";
import TilePaint from "assets/reasons/tile-paint.png";
import TilePaintGif from "assets/reasons/tile-paint.gif";
import TileYoga from "assets/reasons/tile-yoga.png";
import TileYogaGif from "assets/reasons/tile-yoga.gif";
import {
  HUMBLE_SWAP_SECURITY,
  HUMBLE_SWAP_FEES,
  HUMBLE_SWAP_SWAPS,
  HUMBLE_SWAP_API_SDK,
} from "constants/links";
import { useLocation } from "react-router-dom";

const Container = styled(PageContainer)`
  padding-top: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 150px;
  }
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 32px;
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const BgLeft = styled.div`
  background-image: url(${BgReasonsLeft});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 614px;
  left: 0;
  position: absolute;
  width: 18vw;
`;

const BgRight = styled.div`
  background-image: url(${BgReasonsRight});
  background-position: left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  bottom: 0;
  height: 580px;
  position: absolute;
  right: 0;
  width: 48vw;
  @media screen and (max-width: 1024px) {
    bottom: 134px;
  }
`;

const TilesContainer = styled.div`
  margin-top: 100px;
  max-width: 1448px;
`;

const StyledLaunchButton = styled(LaunchAppButton)`
  border-radius: 27px;
  font-size: 18px;
  font-weight: 500;
  height: 54px;
  line-height: 18px;
  margin-top: 80px;
  padding: 18px 48px;
  width: 200px;
  &&:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

const tiles = [
  {
    image: TileWarrior,
    gif: TileWarriorGif,
    title: `Built securely on the
      Reach dApp platform`,
    content: `Reach is the best platform for practical blockchain development, making it
      exponentially faster and cheaper to launch decentralized apps on any network
      while providing the safety you need to grow. Humble is the first company from
      the Reach Labs in-house incubator program and written in Reach.`,
    dark: true,
    marginTop: -135,
    textsHeight: 190,
    className: "first-child",
    url: HUMBLE_SWAP_SECURITY,
  },
  {
    image: TilePig,
    gif: TilePigGif,
    title: `Low fee / high-speed
      transactions`,
    content: `Ditch the high fees and slow transactions of other DeFi platforms. From fast
      frontend to blazing backend, our engineers poured all their brainpower into
      optimizing every bit of Humble.`,
    marginTop: -135,
    textsHeight: 190,
    url: HUMBLE_SWAP_FEES,
  },
  {
    image: TileCoins,
    gif: TileCoinsGif,
    title: `Trade tokens you won’t find
      on Coinbase or Binance`,
    content: `Centralized exchanges like Coinbase or Binance have limited tokens available on
      their platforms. As a decentralized exchange (DEX), Humble empowers you to 
      trade without limits.`,
    marginTop: -105,
    marginBottom: -32,
    textsHeight: 180,
    url: HUMBLE_SWAP_SWAPS,
  },
  {
    image: TileYoga,
    gif: TileYogaGif,
    title: `Smart contracts built
      to offer flexible fees`,
    content: `Humble’s smart contract design will allow for flexible fee structures, allowing for
      opportunities for high-volume traders.`,
    dark: true,
    marginTop: -100,
    marginBottom: 20,
    textsHeight: 180,
    url: HUMBLE_SWAP_FEES,
  },
  {
    image: TilePaint,
    gif: TilePaintGif,
    title: `API/SDK`,
    content: `Humble’s UI/UX is beginner-friendly, but it’s not an entry-level product. API and
      SDK tools will allow for programmatic interaction by the most sophisticated users.`,
    dark: true,
    marginTop: -100,
    textsHeight: 130,
    url: HUMBLE_SWAP_API_SDK,
  },
];

const Reasons = forwardRef<HTMLDivElement>((_props, ref) => {
  const isMobile = useIsMobile();
  const { search } = useLocation();

  return (
    <div ref={ref}>
      <Container className="secondary responsive-width">
        {!isMobile && (
          <>
            <BgLeft />
            <BgRight />
          </>
        )}
        <Title>More reasons to use Humble</Title>
        <TilesContainer>
          {tiles.map((tile, index) => (
            <ReasonTile key={`${index}-${tile.title}`} tile={tile} />
          ))}
        </TilesContainer>
        <StyledLaunchButton
          location={search}
          tagAction="LAUNCH_APP-Why_Humble"
        />
      </Container>
    </div>
  );
});

Reasons.displayName = "Reasons";

export default Reasons;

import { forwardRef, useEffect, useState } from "react";

import styled from "styled-components";
import ImageLoader from "components/common/ImageLoader";
import { FlexColumn, PageContainer } from "components/common/Containers";
import { ActionButton, TransparentButton } from "components/Forms/Button";
import { useIsMobile } from "utils/useScreenSize";
import EmailSignUp from "components/Forms/EmailSignUp";
import { sendGoogleTagEvent } from "utils/googleAnalytics";

// Images
import singleCloud from "assets/humble-products/single-cloud.svg";
import twoStars from "assets/humble-products/two-stars.svg";
import fishes from "assets/humble-products/fishes.png";
import trees from "assets/humble-products/trees.png";
import basket from "assets/humble-products/basket.png";
import contract from "assets/humble-products/contract.png";
import hands from "assets/humble-products/hands.png";
import timeline from "assets/humble-products/timeline.svg";
import menuClose from "assets/menu-close-2.svg";
import { useLocation } from "react-router-dom";

const Content = styled.div<{ h: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ h }) => h}px;
  position: relative;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 60px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const Img = styled.img<{ h: number; w: number }>`
  height: ${({ h }) => h}px;
  width: ${({ w }) => w}px;
`;

const Timeline = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: visible;
  padding: 92px 140px;
  will-change: transform;
  @media screen and (max-width: 1124px) {
    padding: 92px 42px;
  }
  @media screen and (max-width: 768px) {
    padding: 22px 38px;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Title = styled.h2`
  font-size: 60px;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const ModalContainer = styled(FlexColumn)`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 636px;
  margin: 0;
  text-align: center;
  transition: 250ms ease-out;
  z-index: 999;
  border-radius: 16px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  &.active {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: all;
  }
  &.inactive {
    transform: translateX(10%);
    opacity: 0;
    pointer-events: none;
  }
  > * {
    position: initial;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 20px;
  padding: 16px 8px;
`;

const ModalContent = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: 40px ${({ theme }) => `${theme.sizes.lg} ${theme.sizes.lg}`};
  .hs-form-custom {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }
  .error {
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.error};
  }
  .hs-form-booleancheckbox {
    margin-left: 1em;
    margin-top: 2em;
  }
  input {
    border-radius: 8px;
    border: none;
    font-family: "Prompt", sans-serif;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    margin: 8px 0;
    max-width: 372px;
    padding: 0 8px;
    width: 100%;
    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.primary};
    }
  }
  input[type="checkbox"] {
    accent-color: ${({ theme }) => theme.colors.primary};
    height: 20px;
    margin: 0;
    width: 20px;
    + span {
      margin-left: 10px;
    }
  }
  ul.hs-error-msgs,
  ul.inputs-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 372px;
    li {
      margin: 0;
    }
    label {
      display: flex;
      text-align: left;
      width: 100%;
    }
  }
  label {
    &.hs-form-booleancheckbox-display {
      padding: 10px 0;
    }
    &.hs-main-font-element,
    &.hs-error-msg {
      color: ${({ theme }) => theme.colors.error};
      padding: 5px 0;
    }
  }
  .hs-form-custom-button {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 30px;
    border: 0;
    color: ${({ theme }) => theme.colors.accent};
    font-family: "Prompt", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    width: 200px;
  }
  .submitted-message {
    padding: 30px 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 32px;
  min-width: 300px;
  max-width: 300px;
  height: 444px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

type CardProps = {
  title: string;
  text: string;
  ctaLabel: string;
  img: string;
  imgAlt: string;
  ctaAction?: () => void;
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 142px;
`;
const CardTitle = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: 24px;
`;
const CardText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 1em;
`;

const Sticky = styled.div`
  position: sticky;
  top: 100px;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: unset;
  .container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    max-width: 1940px;
  }
`;

function Card({ title, text, ctaLabel, img, imgAlt, ctaAction }: CardProps) {
  const { search } = useLocation();
  return (
    <CardContainer>
      <Img h={120} w={152} src={img} alt={imgAlt} />
      <TextContainer>
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </TextContainer>
      {ctaAction ? (
        <ActionButton variant="action-dark" onClick={ctaAction} size="lg">
          {ctaLabel}
        </ActionButton>
      ) : (
        <a href="https://app.humble.sh" target="_blank" rel="noreferrer">
          <ActionButton
            variant="action"
            onClick={() =>
              sendGoogleTagEvent("LAUNCH_APP-Humble_Products", search)
            }
          >
            Start
          </ActionButton>
        </a>
      )}
    </CardContainer>
  );
}

type Props = {
  horizRef: React.RefObject<HTMLDivElement>;
  stickyRef: React.ForwardedRef<HTMLDivElement>;
  windowSize: { width: number; height: number };
};

const Products = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const [modal, setModal] = useState("");
    const [spaceScroll, setSpaceScroll] = useState(0);
    const isMobile = useIsMobile();

    useEffect(() => {
      if (props.horizRef && props.horizRef.current) {
        const bandWidth = props.horizRef.current.scrollWidth || 0;
        const bandHeight = props.horizRef.current.scrollHeight || 0;
        const padding = isMobile ? 40 : 140;
        setSpaceScroll(
          bandWidth - props.windowSize.width + bandHeight + padding
        );
      }
    }, [props.windowSize]);

    return (
      <Section ref={ref}>
        <PageContainer className="container">
          <TitleContainer>
            <Img h={150} w={150} src={twoStars} alt="Two stars" />
            <Title>Humble Products</Title>
            {!isMobile && <Img h={150} w={150} src={singleCloud} alt="Cloud" />}
          </TitleContainer>
          <Content h={spaceScroll}>
            <Sticky ref={props.stickyRef}>
              <Timeline className="horizontal" ref={props.horizRef}>
                <Cards>
                  <Card
                    title="HumbleSwap"
                    text="Securely swap your favorite tokens in seconds. No registration required!"
                    ctaLabel="Start"
                    img={fishes}
                    imgAlt="Fish Swimming"
                  />
                  <Card
                    title="Humble Analytics"
                    text="Follow smart money and take control of your finances"
                    ctaLabel="Coming Soon"
                    img={trees}
                    imgAlt="Trees"
                    ctaAction={() => setModal("Humble Analytics")}
                  />
                  <Card
                    title="Yield Farming"
                    text="Stake and earn not so humble harvests"
                    ctaLabel="Coming soon"
                    img={basket}
                    imgAlt="Basket"
                    ctaAction={() => setModal("Yield Farming")}
                  />
                  <Card
                    title="Humble DAO"
                    text="Seize the means of governance"
                    ctaLabel="Coming soon"
                    img={hands}
                    imgAlt="Hands up"
                    ctaAction={() => setModal("Humble DAO")}
                  />
                  <Card
                    title="Limit Orders"
                    text="Buy or sell a token at a specific price or better"
                    ctaLabel="Coming soon"
                    img={contract}
                    imgAlt="Contract"
                    ctaAction={() => setModal("Limit Orders")}
                  />
                </Cards>
                <Img src={timeline} h={153} w={1563} />
              </Timeline>
            </Sticky>
          </Content>
        </PageContainer>
        {modal && (
          <ModalContainer>
            <ModalHeader>
              <TransparentButton onClick={() => setModal("")}>
                <ImageLoader alt="menu" src={menuClose} width="16" />
              </TransparentButton>
            </ModalHeader>
            <ModalContent>
              <ModalTitle>{modal}</ModalTitle>
              Get a sneak peak of our designs and be notified when it launches
              <EmailSignUp />
            </ModalContent>
          </ModalContainer>
        )}
      </Section>
    );
  }
);

Products.displayName = "Products";

export default Products;

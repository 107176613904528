import { createGlobalStyle } from "styled-components";

const GLOBAL_THEME = {
  GLOBAL: createGlobalStyle<any>`

  body {
    background-color: ${({ theme: t }) => t.colors.bgColor};
    background-image: ${({ theme: t }) => t.colors.bgGradient};
    color: ${({ theme: t }) => t.colors.primary};
    transition: background-color 0.4s ease-out;
  
    a {
      color: ${({ theme: t }) => t.colors.accent};
      text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .subheading {
      color: ${({ theme: t }) => t.colors.primary};
      margin: 0;
    }
    
    h1, .h1 { font-size: 3.5rem; }
    h2, .h2 { font-size: 2.6rem; }
    h3, .h3 { font-size: 1.8rem; }
    h4, .h4 { font-size: 1.4rem; }
    h5, .h5 { font-size: 1.2rem; }
    h6, .h6 { font-size: 1rem; font-weigh: bold; }

    .accent { 
      background-color: ${({ theme: t }) => t.colors.accent}; 
    }
    .bgColor { 
      background-color: ${({ theme: t }) => t.colors.bgColor}; 
    }
    .error { 
      background-color: ${({ theme: t }) => t.colors.error}; 
    }
    .grey {
      background-color: #9c9c9c;
    }
    .primary { 
      background-color: ${({ theme: t }) => t.colors.primary}; 
    }
    .secondary { 
      background-color: ${({ theme: t }) => t.colors.secondary}; 
    }
    .warning { 
      background-color: ${({ theme: t }) => t.colors.warning};
    }
    .accent--text{ 
      color: ${({ theme: t }) => t.colors.accent}; 
    }
    .bgColor--text{ 
      color: ${({ theme: t }) => t.colors.bgColor}; 
    }
    .error--text{ 
      color: ${({ theme: t }) => t.colors.error}; 
    }
    .grey--text {
      color: #9c9c9c;
    }
    .primary--text{ 
      color: ${({ theme: t }) => t.colors.primary}; 
    }
    .secondary--text{ 
      color: ${({ theme: t }) => t.colors.secondary}; 
    }
    .warning--text{ 
     color: ${({ theme: t }) => t.colors.warning};
    }

    .responsive-width {
      width: 100%;
      padding-left: 8%;
      padding-right: 8%;

      @media screen and (max-width: 1124px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @media screen and (max-width: 768px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      @media screen and (max-width: 480px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
  `,
};

const colors = {
  accent: "#FFBE1D",
  bgColor: "#fefdf9",
  error: "#db362c",
  primary: "#191713",
  secondary: "#FFBE1D",
  warning: "#FFBE1D",
  border: "#F8F4E6",
  white: "#fff",
  orange: "#F2933C",
  darkGray: "#4F524C",
  lightGray: "#9CA1AA",
  darkSage: "#6C9688",
};

const sizes = {
  xxs: "0.125rem",
  xs: "0.3rem",
  sm: "0.6rem",
  default: "1rem",
  md: "1.4rem",
  lg: "2.1rem",
  xlg: "2.6rem",
  xxlg: "3.2rem",
  xxxlg: "4.8rem",
};

const presets = {
  contentWidth: "1300px",
  rounded: {
    xlg: `100%`,
    lg: `32px`,
    md: `16px`,
    sm: `8px`,
  },
};

/* Global Application Style Theme */
const THEME = {
  ...GLOBAL_THEME,
  colors,
  sizes,
  presets,
};

export default THEME;

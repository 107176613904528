/* eslint-disable import/prefer-default-export */
export const HUMBLE_SWAP_FAQ = "https://support.humble.sh/442739-FAQ";
export const HUMBLE_SWAP_REPORT_BUG = "https://forms.humble.sh/report-a-bug";
export const HUMBLE_SWAP_FEATURE_REQUEST = "https://forms.humble.sh/feature-request";
export const HUMBLE_SWAP_BECOME_A_PARTNER = "/become-a-partner";
export const HUMBLE_SWAP_TERMS_AND_CONDITIONS =
  "https://support.humble.sh/028119-Terms--Conditions";
export const HUMBLE_SWAP_PRIVACY_POLICY =
  "https://support.humble.sh/797011-Privacy-Policy";
export const HUMBLE_SWAP_SECURITY =
  "https://support.humble.sh/011647-Is-HumbleSwap-Secure";
export const HUMBLE_SWAP_FEES =
  "https://support.humble.sh/324519-What-Are-Fees-And-How-Do-They-Work";
export const HUMBLE_SWAP_SWAPS = "https://support.humble.sh/749165-How-to-Swap";
export const HUMBLE_SWAP_API_SDK = "https://support.humble.sh/163969-APISDK";
export const REACH_WEB = "https://reach.sh";

import { ComponentPropsWithRef } from "react";
import { DefaultTheme } from "styled-components";
import { HSGTagEvent } from "utils/googleAnalytics";

type CustomProps = {
  theme: DefaultTheme;
  round?: boolean;
  size?: "sm" | "md" | "lg";
  variant?: "dark" | "action" | "action-dark" | "outlined" | "transparent";
  location?: string;
  tagAction?: HSGTagEvent;
};

export type AllButtonLinkProps = ComponentPropsWithRef<"a"> & CustomProps;

type AllButtonProps = ComponentPropsWithRef<"button"> & CustomProps;
export default AllButtonProps;

export function bgColor({ theme, variant }: CustomProps) {
  if (!variant) return theme.colors.secondary;
  if (variant === "transparent") return "transparent";
  if (variant === "dark") return theme.colors.primary;
  if (variant === "action") return theme.colors.secondary;
  if (variant === "action-dark") return theme.colors.darkGray;
  return "transparent";
}

export function bgColorHover({ theme, variant }: CustomProps) {
  if (!variant) return theme.colors.primary;
  if (variant === "transparent") return "transparent";
  if (variant === "dark") return theme.colors.secondary;
  if (variant === "action") return theme.colors.orange;
  if (variant === "action-dark") return theme.colors.lightGray;
  return "transparent";
}

export function border(/* _props: CustomProps */) {
  return 0;
}

export function borderRadius({ round, theme }: CustomProps) {
  const { rounded } = theme.presets;
  return round ? rounded.lg : rounded.md;
}

export function minHeight({ size, theme }: CustomProps) {
  const { md, lg } = theme.sizes;
  if (size === "lg") return lg;
  if (size === "md") return md;
  return "initial";
}

export function textColor({ theme, variant }: CustomProps) {
  if (!variant) return theme.colors.primary;
  if (variant === "dark") return theme.colors.secondary;
  if (variant === "action") return theme.colors.primary;
  if (variant === "action-dark") return theme.colors.secondary;
  return "transparent";
}

export function textColorHover({ theme, variant }: CustomProps) {
  if (!variant) return theme.colors.secondary;
  if (variant === "dark") return theme.colors.primary;
  if (variant === "action") return theme.colors.primary;
  if (variant === "action-dark") return theme.colors.primary;
  return "transparent";
}

export function width({ size }: CustomProps) {
  return size === "lg" ? "100%" : "initial";
}

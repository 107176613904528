import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import hero1 from "assets/hero-01.png";
import hero2 from "assets/hero-02.png";
import hero3 from "assets/hero-03.png";
import hero4 from "assets/hero-04.png";
import hero5 from "assets/hero-05.png";
import hero6 from "assets/hero-06.png";
import LaunchButton from "components/LaunchAppButton";
import { useLocation } from "react-router-dom";

const Container = styled(PageContainer)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-areas: "image-left title image-right";
  grid-template-columns: 1fr auto 1fr;
  padding-bottom: 160px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 120px;
  }
`;

const LaunchAppButton = styled(LaunchButton)`
  border-radius: 30px;
  font-size: 18px;
  height: 54px;
  left: -50%;
  position: relative;
`;

const Cont = styled.div`
  grid-area: title;
  height: 800px;
  max-width: 640px;
  position: relative;
  width: 50vw;
  @media only screen and (max-width: 768px) {
    height: 600px;
  }
`;

const CTAContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  left: 50%;
  margin: auto;
  max-height: 100vh;
  overflow: visible;
  position: absolute;
  top: 15em;
  width: 623px;
  @media only screen and (max-width: 768px) {
    top: 8em;
  }
`;

const DeFiForAll = styled.h1`
  color: #000000;
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  left: -50%;
  line-height: 120px;
  margin-bottom: 60px;
  max-width: 22.6875em;
  min-width: 267px;
  position: relative;
  text-align: center;
  @media only screen and (max-width: 1024px) {
    font-size: 100px;
    line-height: 100px;
  }
  @media only screen and (max-width: 768px) {
    font-size: 60px;
    height: 120px;
    line-height: 60px;
    margin-bottom: 30px;
    width: 267px;
  }
`;

const CallToAction = styled.h4`
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 162px;
  left: -50%;
  line-height: 27px;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
  width: 100%:
`;

const ImageLeft = styled.div`
  background-image: url(${hero1});
  background-repeat: no-repeat;
  background-size: 100%;
  grid-area: image-left;
  height: 600px;
  justify-self: end;
  margin-top: 165px;
  position: absolute;
  top: 0px;
  width: 521px;
  @media only screen and (max-width: 1024px) {
    background-image: url(${hero3});
    background-size: 255px 623px;
    height: 630px;
    justify-self: start;
  }
  @media only screen and (max-width: 768px) {
    background-image: url(${hero5});
    background-size: 95px 164px;
    margin-top: 100px;
    position: absolute;
  }
`;

const ImageRight = styled.div`
  background-image: url(${hero2});
  background-repeat: no-repeat;
  background-size: 100%;
  grid-area: image-right;
  height: 600px;
  justify-self: start;
  margin-top: 165px;
  position: absolute;
  width: 521px;
  @media only screen and (max-width: 1024px) {
    background-image: url(${hero4});
    background-position: center right;
    background-size: 265px 623px;
    height: 630px;
    justify-self: end;
  }
  @media only screen and (max-width: 768px) {
    background-image: url(${hero6});
    background-position: bottom right;
    background-size: 135px 164px;
    height: 700px;
    margin-top: 50px;
  }
`;

function Cover() {
  const { search } = useLocation();
  return (
    <Container>
      <ImageLeft />
      <ImageRight />
      <Cont>
        <CTAContainer>
          <DeFiForAll>Humble DeFi Merges with Pact Fi</DeFiForAll>
          <CallToAction>
            Humble DeFi and Pact Fi are pleased to announce the completion of a
            merger, creating a DeFi powerhouse that draws upon the unique value
            each company brings. With this merger, we will be winding down
            Humbles UI, and setting LPs to withdraw only. We are calling on all
            our users to migrate to their respective Pact Pools by October 14th
            as we take this next exciting step in our journey to taking over the
            DeFi space.
            <br />
            <br />
            You can learn more about this merger here:{" "}
            <a
              href="https://medium.com/humbledefi/humble-defi-pact-merger-619a9205e051"
              target="_blank"
              rel="noreferrer"
            >
              https://medium.com/humbledefi
            </a>
          </CallToAction>
          {/*
          <LaunchAppButton
            tagAction="LAUNCH_APP-Defi_Cover"
            location={search}
          />
  */}
        </CTAContainer>
      </Cont>
    </Container>
  );
}
export default Cover;

import styled, { css } from "styled-components";
import AllButtonProps, * as UI from "./Button.Helpers";

const buttonCSS = css`
  align-items: center;
  background-color: ${UI.bgColor};
  border-radius: ${UI.borderRadius};
  border: ${UI.border};
  color: ${UI.textColor};
  cursor: pointer;
  display: inline-flex;
  font-family: "Outfit", sans-serif;
  margin: 0;
  min-height: ${UI.minHeight};
  padding: ${({ theme }) => theme.sizes.sm};
  place-content: center;
  transition: background-color ease-out 250ms;
  width: ${UI.width};

  &:not([disabled]):hover {
    background-color: ${UI.bgColorHover};
    color: ${UI.textColorHover};
    transform: scale(0.99, 0.99);
    transition: 0.12s linear;
  }

  &[disabled] {
    transition: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const smallTextCSS = css`
  font-size: smaller;
  font-weight: bolder;
`;

const ButtonBase = styled.button<AllButtonProps>`
  ${buttonCSS}

  a {
    color: inherit;
  }

  > a {
    height: 100%;
    width: 100%;
  }
`;

export const ButtonLink = styled.a<UI.AllButtonLinkProps>`
  ${buttonCSS}
  ${smallTextCSS}
  padding: ${({ theme }) => `${theme.sizes.xs} ${theme.sizes.lg}`};
`;

export const ActionButton = styled(ButtonBase).attrs({ round: true })`
  flex-shrink: 0;
  padding: 0 ${({ theme }) => theme.sizes.md};
  line-height: ${({ theme }) => theme.sizes.xlg};
  font-family: "Prompt", sans-serif;
  font-size: 18px;
  font-weight: 500;
  width: 200px;
  height: 54px;
`;

export const TransparentButton = styled(ButtonBase).attrs({
  variant: "transparent",
})``;

const DefaultButton = styled(ButtonBase)``;
export default DefaultButton;

import { useEffect, useState } from "react";

export const MOBILE_SCREEN_THRESHOLD = 768;
export const TABLET_SCREEN_THRESHOLD = 1024;

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function handleWindowSizeChange() {
    setScreenSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return screenSize;
};

export const useIsMobile = () => {
  const screenSize = useScreenSize();
  return screenSize.width <= MOBILE_SCREEN_THRESHOLD;
};

export const useIsTablet = () => {
  const screenSize = useScreenSize();
  return screenSize.width <= TABLET_SCREEN_THRESHOLD;
};

export default useScreenSize;

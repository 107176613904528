import { forwardRef } from "react";
import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import SocialIcon from "components/common/SocialIcon";
import THEME from "theme";

import AvatarChris from "assets/team/Chris.png";
import AvatarJay from "assets/team/Jay.png";
import AvatarAndrew from "assets/team/Andrew.png";
import AvatarNicholas from "assets/team/Nicholas.png";
import { ReactComponent as LinkedinIcon } from "assets/social/black-sm-linkedin.svg";
import { ReactComponent as TwitterIcon } from "assets/social/black-sm-twitter.svg";
import BottomBgImg from "assets/team/team-bottom-bg-sm.svg";

const Container = styled(PageContainer)`
  padding-top: 120px;
  padding-bottom: 110px;
  z-index: 200;
  @media screen and (max-width: 768px) {
    padding-top: 150px;
    padding-bottom: 200px;
  }
`;

const WidthWrapper = styled.div`
  max-width: 1078px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
  @media screen and (max-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 32px;
    line-height: 32px;
  }
`;

const Description = styled.div`
  margin-top: 24px;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  @media screen and (max-width: 768px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
  gap: 50px;
  @media screen and (max-width: 1124px) {
    max-width: 944px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 1024px) {
    display: block;
    margin-top: -24px;
  }
`;

const Person = styled.div`
  width: 215px;
  @media screen and (max-width: 1024px) {
    display: inline-block;
    margin-top: 64px;
    margin-left: 11px;
    margin-right: 11px;
  }
`;

const Avatar = styled.img`
  width: 215px;
  height: 215px;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 16px;
`;

const Role = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
`;

const Social = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const BottomBg = styled.div`
  background-image: url(${BottomBgImg});
  height: 375px;
  width: 100%;
  margin-top: -200px;
  z-index: 100;
`;

const team = [
  /*
  {
    avatar: AvatarChris,
    name: "Chris Swenor",
    role: "Co-founder & CEO",
    linkedin: "https://www.linkedin.com/in/chrisswenor/",
    twitter: "https://twitter.com/chrisswenor",
  },
  {
    avatar: AvatarJay,
    name: "Dr. Jay McCarthy",
    role: "Co-founder & CTO",
    linkedin: "https://www.linkedin.com/in/jay-mccarthy-34a6b95/",
  },
  */
  {
    avatar: AvatarAndrew,
    name: "Andrew Kotulak",
    role: "Head of DeFi",
    linkedin: "https://www.linkedin.com/in/andrew-kotulak-business/",
    twitter: "https://twitter.com/AndrewKotulak",
  },
  {
    avatar: AvatarNicholas,
    name: "Nick Shellabarger",
    role: "Head of Engineering",
    linkedin: "https://www.linkedin.com/in/nicholas-shellabarger-121301150/",
    twitter: "https://twitter.com/NicholasShella2",
  },
];

const Team = forwardRef<HTMLDivElement>((_props, ref) => (
  <div className="primary" ref={ref}>
    <Container className="secondary responsive-width">
      <WidthWrapper>
        <Title>DeFi Dream Team</Title>
        <Description>
          Serial innovators proud to put their real identities behind their
          work.
        </Description>
        <TeamContainer>
          {team.map((person, index) => (
            <Person key={`${index}-${person.name}`}>
              <Avatar src={person.avatar} />
              <Name>{person.name}</Name>
              <Role>{person.role}</Role>
              <Social>
                {person.linkedin && (
                  <SocialIcon
                    icon={LinkedinIcon}
                    href={person.linkedin}
                    hoverColor={THEME.colors.darkGray}
                    size={40}
                  />
                )}
                {person.twitter && (
                  <SocialIcon
                    icon={TwitterIcon}
                    href={person.twitter}
                    hoverColor={THEME.colors.darkGray}
                    size={40}
                  />
                )}
              </Social>
            </Person>
          ))}
        </TeamContainer>
      </WidthWrapper>
    </Container>
    <BottomBg />
  </div>
));

Team.displayName = "Team";

export default Team;

import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import { useIsMobile, useIsTablet } from "utils/useScreenSize";
import plane from "assets/support/plane.svg";
import line from "assets/support/line.svg";
import michelleDesktop from "assets/support/michelle-desktop-640x360.gif";
import michelleTablet from "assets/support/michelle-tablet-430x242.gif";
import customer from "assets/support/customer.png";
import customerText from "assets/support/customer-text.png";
import chat from "assets/support/chat-desktop-640x383.png";

const Container = styled(PageContainer)`
  padding: 42px;
  padding-bottom: 192px;
  @media screen and (max-width: 1024px) {
    padding-bottom: 100px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 50px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  width: 100%;
  &.icon {
    height: 150px;
    width: 150px;
  }
  &.side {
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  &.flip {
    transform: scaleX(-1);
  }
  &.rounded {
    border-radius: 16px;
  }
  &.animated {
    transform: scale(0);
    &.show {
      transform: scale(1);
      transition: transform 1s;
    }
  }
`;

const SubSection = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 60px;
  justify-content: center;
  margin-bottom: 60px;
  .space {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

const Content = styled.div<{ h: number }>`
  display: flex;
  flex-direction: column;
  height: ${({ h }) => h}px;
  padding-bottom: 100px;
  position: relative;
  width: 100%;
`;

const TextWrapper = styled.div``;

const Title = styled.h1`
  font-family: "Prompt", sans-serif;
  font-size: 60px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.darkGray};
  font-family: "Prompt", sans-serif;
  font-size: 24px;
  font-weight: 500;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin-top: 24px;
  }
`;

const Description = styled.p`
  font-family: "Prompt", sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

const Block = styled.div`
  align-items: end;
  column-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  grid-template-rows: auto auto;
  justify-items: center;
  max-width: 1448px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  overflow: visible;
  padding: 62px 0px;
  will-change: transform;
`;

const ChatImg = styled.div`
  aspect-ratio: 16/10;
  background-image: url(${chat});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 768px) {
    max-width: none;
    width: calc(100vw - 84px);
  }
`;

const MichelleImg = styled.div`
  aspect-ratio: 16/9;
  background-image: url(${michelleDesktop});
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 768px) {
    background-image: url(${michelleDesktop});
    max-width: none;
    width: calc(100vw - 84px);
  }
  @media screen and (max-width: 1024px) {
    background-image: url(${michelleTablet});
  }
`;

const LineImg = styled.div`
  align-self: center;
  background-image: url(${line});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 16px;
  height: 60%;
  max-width: 40px;
  overflow: hidden;
  width: 40px;
`;

const BlockText = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  font-family: "Prompt", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: 32px;
  text-align: left;
  i {
    font-size: 12px;
    font-weight: 400;
  }
`;

const Text = styled.div`
  margin-left: min(16vw, 285px);
  margin-right: 20px;
  @media screen and (max-width: 1024px) {
    margin-left: 18vw;
  }
  @media screen and (max-width: 768px) {
    margin-left: 40vw;
  }
`;

const Sticky = styled.div`
  position: sticky;
  top: 100px;
  width: 100%;
`;

const Customer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: -10%;
  max-width: 285px;
  position: absolute;
  top: -140px;
  width: 19vw;
  img {
    position: relative;
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    left: -14%;
    top: -120px;
    width: 22vw;
    .customer-text {
      width: 15vw;
    }
  }
  @media screen and (max-width: 768px) {
    left: -6%;
    top: -120px;
    width: 40vw;
    .customer-text {
      width: 24vw;
    }
  }
`;

type Props = {
  horizRef: React.RefObject<HTMLDivElement>;
  stickyRef: React.ForwardedRef<HTMLDivElement>;
  customerRef: React.ForwardedRef<HTMLDivElement>;
  custTextRef: React.ForwardedRef<HTMLImageElement>;
  windowSize: { width: number; height: number };
};

const Support = forwardRef(
  (
    { horizRef, stickyRef, customerRef, custTextRef, windowSize }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const [spaceScroll, setSpaceScroll] = useState(0);
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    useEffect(() => {
      if (horizRef && horizRef.current) {
        const bandWidth = horizRef.current.scrollWidth || 0;
        const bandHeight = horizRef.current.scrollHeight || 0;
        const padding = 214;
        setSpaceScroll(bandWidth - windowSize.width + bandHeight + padding);
      }
    }, [windowSize]);

    return (
      <Container ref={ref}>
        <Image className="icon" src={plane} />
        <SubSection>
          {!isTablet && <Image className="icon side flip" src={plane} />}
          <TextWrapper>
            <Title>Say goodbye to DIY DeFi</Title>
            <Subtitle>And say hello to human customer support</Subtitle>
            <Description>
              Technically, we call it Customer Success, but you can say, “Hi,
              Michelle”.
              <br />
              <b>Human help</b> powered by actual people, not chatbots and
              unanswered emails.
            </Description>
          </TextWrapper>
          {!isTablet && <div className="space" />}
        </SubSection>
        {isMobile && (
          <Content h={spaceScroll}>
            <Sticky ref={stickyRef}>
              <Horizontal ref={horizRef}>
                <Block>
                  <ChatImg />
                  <MichelleImg />
                  <BlockText>
                    Currently, your only options on other DEX platforms are blog
                    posts and chatbots - so we decided to change that.
                  </BlockText>
                  <BlockText>
                    <Text>
                      Get easy answers to tough questions from real people.
                      <br />
                      <i>
                        Note: Communication with HumbleSwap specialists
                        <strong> IS NOT </strong>financial advice.
                      </i>
                    </Text>
                    <Customer ref={customerRef}>
                      <Image
                        className="customer-text animated"
                        src={customerText}
                        ref={custTextRef}
                        alt="Hi Michelle!"
                      />
                      <Image src={customer} alt="Customer" />
                    </Customer>
                  </BlockText>
                </Block>
              </Horizontal>
            </Sticky>
          </Content>
        )}
        {!isMobile && (
          <SubSection>
            <Block>
              <ChatImg />
              <LineImg />
              <MichelleImg />
              <BlockText>
                Currently your only options on other DEX platforms are blog
                posts and chat bots - so we decided to change that.
              </BlockText>
              <div />
              <BlockText>
                <Customer ref={customerRef}>
                  <Image
                    className="customer-text animated"
                    src={customerText}
                    ref={custTextRef}
                    alt="Hi Michelle!"
                  />
                  <Image src={customer} alt="Customer" />
                </Customer>
                <Text>
                  Get easy answers to tough questions from real people.
                  <br />
                  <i>
                    Note: Communication with HumbleSwap specialists
                    <strong> IS NOT </strong>financial advice.
                  </i>
                </Text>
              </BlockText>
            </Block>
          </SubSection>
        )}
      </Container>
    );
  }
);

Support.displayName = "Support";

export default Support;

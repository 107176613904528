import { useState } from "react";
import styled from "styled-components";
import ImageLoader from "./common/ImageLoader";
import { FlexRow, FlexColumn } from "./common/Containers";
import { ButtonLink, TransparentButton } from "./Forms/Button";
import LaunchAppButton from "./LaunchAppButton";
import SocialLinks from "./SocialLinks";
import { useIsMobile, useIsTablet } from "utils/useScreenSize";

import logo from "assets/logo-defi-suite.svg";
import menuHamburger from "assets/menu-hamburger.svg";
import menuClose from "assets/menu-close.svg";
import { useLocation } from "react-router-dom";

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const MenuSection = styled.div<{
  flexGrow?: number;
  justifyContent?: "flex-end" | "flex-start";
}>`
  display: flex;
  flex: ${({ flexGrow }) => flexGrow || 1} 1 0px;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
`;

const Container = styled(FlexRow)<{ isMobile?: boolean }>`
  background-color: ${({ theme }) => theme.colors.bgColor};
  height: 100px;
  padding: ${({ isMobile }) => (isMobile ? "0 16px" : "0 40px")};
  place-content: space-between;
  position: sticky;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  top: 0;
  width: 100%;
  z-index: 999;
  .logo {
    cursor: pointer;
  }
`;

const MiddleSection = styled.div<{ isTablet?: boolean }>`
  display: flex;
  justify-content: space-around;
  flex-direction: ${({ isTablet }) => (isTablet ? "column" : "row")};
  font-size: 16px;
  gap: ${({ isTablet }) => (isTablet ? "20px" : "unset")};
  margin-bottom: ${({ isTablet }) => (isTablet ? "148px" : "0")};
  max-width: 580px;
  width: 100%;
`;

const SectionFocus = styled.span<{ isTablet?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const Logo = styled(ButtonLink)`
  padding: 0;
  margin: 0;
  &:not([disabled]):hover {
    transform: none;
    transition: none;
  }
`;

const ModalContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.bgColor};
  height: 100vh;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  text-align: left;
  transition: 250ms ease-out;
  width: 100%;
  z-index: 999;

  &.active {
    transform: translateX(0%);
    opacity: 1;
    pointer-events: all;
  }

  &.inactive {
    transform: translateX(10%);
    opacity: 0;
    pointer-events: none;
  }

  > * {
    position: initial;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  padding: 0 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px ${({ theme }) => `${theme.sizes.lg} ${theme.sizes.lg}`};
  align-items: center;
`;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export type ScrollIdType =
  | "app"
  | "products"
  | "learn"
  | "community"
  | "support"
  | "team"
  | "whyHumble";

type ScrollType = {
  label: string;
  id: ScrollIdType;
};

const sections: ScrollType[] = [
  /*
  { label: "Products", id: "products" },
  { label: "Why Humble", id: "whyHumble" },
  { label: "Learn", id: "learn" },
  { label: "Community", id: "community" },
  { label: "Team", id: "team" },
  */
];

function AppHeader({
  refHash,
}: {
  refHash: { [key in ScrollIdType]: React.RefObject<HTMLDivElement> };
}) {
  const { search } = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [open, showMenu] = useState(false);
  const navigateHome = () => {
    if (refHash.app.current) {
      refHash.app.current.scroll({ top: 0, behavior: "smooth" });
    }
  };

  const scrollTo = (id: ScrollIdType) => {
    const ref = refHash[id];
    if (ref.current && refHash.app.current) {
      refHash.app.current.scroll({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
    showMenu(false);
  };

  return (
    <Container id="header" isMobile={isMobile}>
      <MenuSection justifyContent="flex-start">
        <Logo role="button" onClick={navigateHome} variant="transparent">
          <ImageLoader className="logo" src={logo} />
        </Logo>
      </MenuSection>

      {!isTablet && (
        <MenuSection flexGrow={2}>
          <MiddleSection>
            {sections.map((f) => (
              <SectionFocus key={f.label} onClick={() => scrollTo(f.id)}>
                {f.label}
              </SectionFocus>
            ))}
          </MiddleSection>
        </MenuSection>
      )}

      <MenuSection justifyContent="flex-end">
        <ActionContainer>
          {isTablet && (
            <TransparentButton onClick={() => showMenu(true)}>
              <ImageLoader alt="menu" src={menuHamburger} width="40" />
            </TransparentButton>
          )}
          {!isTablet && <SocialLinks />}
          {!isTablet && (
            <LaunchAppButton
              size="lg"
              tagAction="LAUNCH_APP-Navigation_Bar"
              location={search}
            />
          )}
        </ActionContainer>
      </MenuSection>

      {isTablet && open && (
        <ModalContainer>
          <ModalHeader>
            <Logo role="button" onClick={navigateHome} variant="transparent">
              <ImageLoader className="logo" src={logo} width="120" />
            </Logo>
            <TransparentButton onClick={() => showMenu(false)}>
              <ImageLoader alt="menu" src={menuClose} width="40" />
            </TransparentButton>
          </ModalHeader>
          <ModalContent>
            <MiddleSection isTablet>
              {sections.map((f) => (
                <SectionFocus
                  key={f.label}
                  onClick={() => scrollTo(f.id)}
                  isTablet
                >
                  {f.label}
                </SectionFocus>
              ))}
            </MiddleSection>
            <BottomSection>
              <SocialLinks />
              <LaunchAppButton
                size="lg"
                tagAction="LAUNCH_APP-Navigation_Bar"
                location={search}
              />
            </BottomSection>
          </ModalContent>
        </ModalContainer>
      )}
    </Container>
  );
}

export default AppHeader;

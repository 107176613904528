import { forwardRef } from "react";
import ReactPlayer from "react-player/lazy";
import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import { useIsMobile } from "utils/useScreenSize";
import PenPaper from "assets/learn/pen-paper.svg";
import Earphone from "assets/learn/earphone.svg";

const Container = styled(PageContainer)`
  padding-top: 120px;
  @media screen and (max-width: 768px) {
    padding-top: 150px;
  }
`;

const WidthWrapper = styled.div`
  max-width: 800px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
  @media screen and (max-width: 768px) {
    padding-left: 60px;
    padding-right: 60px;
    font-size: 32px;
    line-height: 32px;
  }
`;

const HeaderLeftImage = styled.img`
  width: 150px;
  height: 150px;
`;

const HeaderRightImage = styled.img`
  width: 150px;
  height: 150px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Description = styled.div`
  margin-top: 24px;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  @media screen and (max-width: 768px) {
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  background: black;
  border-radius: 20px;
  margin-top: 39px;
  display: flex;
  overflow: hidden;
  border: none;
  position: relative;
  padding-top: 56.25%; // Aspect ratio 16/9
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    * {
      position: relative;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 24px;
  }
`;

const Learn = forwardRef<HTMLDivElement>((_props, ref) => {
  const isMobile = useIsMobile();
  // REACT_APP_YOUTUBE_LINKS is a comma separated youtube videos list of links
  const youTubeLinks = (process.env.REACT_APP_YOUTUBE_LINKS || "").split(",");
  const isVideoListEmpty = youTubeLinks[0] === "";

  return (
    <div ref={ref}>
      <Container className="secondary responsive-width">
        <WidthWrapper>
          <Header>
            <HeaderLeftImage src={isMobile ? Earphone : PenPaper} />
            <Title>Learn</Title>
            <HeaderRightImage src={Earphone} />
          </Header>
          <Description>
            Beginner guides, walk throughs, and practical tips for everyone.
          </Description>
          {!isVideoListEmpty && (
            <VideoWrapper>
              <ReactPlayer
                className="react-player"
                url={youTubeLinks}
                width="100%"
                height="100%"
              />
            </VideoWrapper>
          )}
        </WidthWrapper>
      </Container>
    </div>
  );
});

Learn.displayName = "Learn";

export default Learn;

import { useState } from "react";
import styled from "styled-components";

const Container = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})<{ dark?: boolean }>`
  background: ${({ theme, dark }) =>
    dark ? theme.colors.primary : theme.colors.bgColor};
  border-radius: 16px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme, dark }) =>
    dark ? theme.colors.white : theme.colors.primary};
  display: inline-block;
  float: left;
  height: 346px;
  margin-top: 48px;
  padding: 32px;
  position: relative;
  text-align: left;
  width: calc((100% - 48px) / 2);

  * {
    pointer-events: none;
  }

  &&:nth-child(2n) {
    margin-left: 24px;
  }
  &&:nth-child(2n-1) {
    margin-right: 24px;
  }

  @media screen and (max-width: 1480px) {
    height: 380px;
  }

  @media screen and (max-width: 1248px) {
    height: 440px;
    margin-top: 40px;
    width: calc((100% - 20px) / 2);

    &&:nth-child(2n) {
      margin-left: 10px;
    }
    &&:nth-child(2n-1) {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 1124px) {
    height: 460px;
  }

  @media screen and (max-width: 1024px) {
    height: 490px;
  }

  @media screen and (max-width: 980px) {
    height: 520px;
  }

  @media screen and (max-width: 860px) {
    height: 500px;
    width: 100%;

    &&:nth-child(2n) {
      margin-left: 0px;
    }
    &&:nth-child(2n-1) {
      margin-right: 0px;
    }
  }
`;

const ImageWrapper = styled.div<{ marginTop?: number; marginBottom?: number }>`
  height: 300px;
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"}px;
  margin-top: ${({ marginTop }) => marginTop || "0"}px;
  position: absolute;
  right: 0;
  width: 300px;

  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &&.first-child {
      margin-top: ${({ marginTop }) => marginTop || "0"}px;
    }
    margin-top: ${({ marginTop }) => (marginTop ? marginTop + 50 : "0")}px;
  }
`;

const TileImage = styled.img`
  height: 100%;
  object-fit: contain;
  position: absolute;
  width: 100%;
`;

const TileTexts = styled.div<{ textsHeight?: number }>`
  bottom: 0;
  height: ${({ textsHeight }) => textsHeight || "0"}px;
  position: absolute;
  width: calc(100% - 64px);

  @media screen and (max-width: 1480px) {
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 30 : "0")}px;
  }

  @media screen and (max-width: 1248px) {
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 50 : "0")}px;
  }

  @media screen and (max-width: 1124px) {
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 52 : "0")}px;
  }

  @media screen and (max-width: 1024px) {
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 70 : "0")}px;
  }

  @media screen and (max-width: 980px) {
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 100 : "0")}px;
  }

  @media screen and (max-width: 860px) {
    height: ${({ textsHeight }) => textsHeight || "0"}px;
    bottom: 32px;
  }

  @media screen and (max-width: 640px) {
    &&.first-child {
      height: ${({ textsHeight }) => (textsHeight ? textsHeight + 100 : "0")}px;
    }
    height: ${({ textsHeight }) => (textsHeight ? textsHeight + 40 : "0")}px;
  }
`;

const TileTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  width: 290px;
`;

const TileContent = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: 16px;
`;

export interface TileProp {
  tile: {
    image?: string;
    gif?: string;
    title?: string;
    content?: string;
    dark?: boolean;
    marginTop?: number;
    marginBottom?: number;
    textsHeight?: number;
    className?: string;
    url?: string;
  };
}

function ReasonTile({
  tile: {
    image,
    gif,
    title,
    content,
    dark,
    marginTop,
    marginBottom,
    textsHeight,
    className,
    url,
  },
}: TileProp) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Container
      dark={dark}
      href={url}
      onMouseOut={() => setIsHovered(false)}
      onMouseOver={() => setIsHovered(true)}
    >
      <ImageWrapper
        className={className}
        marginBottom={marginBottom}
        marginTop={marginTop}
      >
        <TileImage src={isHovered ? gif : image} />
      </ImageWrapper>
      <TileTexts className={className} textsHeight={textsHeight}>
        <TileTitle>{title}</TileTitle>
        <TileContent>{content}</TileContent>
      </TileTexts>
    </Container>
  );
}

export default ReasonTile;

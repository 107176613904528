import styled from "styled-components";
import { PageContainer } from "components/common/Containers";
import Partners from "components/Partners";
//  import { HUMBLE_SWAP_BECOME_A_PARTNER } from "constants/links";

const Container = styled(PageContainer)`
  background: ${({ theme }) => theme.colors.primary};
  font-family: "Prompt", sans-serif;
  display: flex;
  flex-direction: column;
  padding-bottom: 140px;
`;

const StyledH2 = styled.h2`
  height: 2.8em;
  margin-top: 2.875em;
  font-style: normal;
  font-weight: 500;
  font-size: 3.75em;
  line-height: 3.75em;
  text-align: center;
  color: ${({ theme }) => theme.colors.bgColor};

  @media only screen and (max-width: 600px) {
    width: 19.4375em;
    height: 1.5em;
    font-weight: 500;
    font-size: 2em;
    line-height: 24px;
  }
`;

/*
const BecomePartnerButton = styled.button`
  padding: 0.75em 1.875em;
  width: auto;
  height: 3em;
  background: ${({ theme }) => theme.colors.darkGray};
  border-radius: 1.875em;
  border: 0;
  margin-top: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerButtonText = styled.p`
  height: 1.125em;
  width: 15.375em;
  font-family: Prompt;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.125em;
  white-space: nowrap;
  text-align: center;
  color: ${({ theme }) => theme.colors.accent};
`;
*/

const PartnerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 960px;
  align-items: center;
  justify-content: space-evenly;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

function Brands() {
  return (
    <Container>
      <StyledH2>Trusted by the best</StyledH2>
      <PartnerBox>
        <Partners />
      </PartnerBox>
      {/* <BecomePartnerButton>
        <a href={HUMBLE_SWAP_BECOME_A_PARTNER} rel="noopener noreferrer">
          <PartnerButtonText>Want to become a partner?</PartnerButtonText>
        </a>
  </BecomePartnerButton>  */}
    </Container>
  );
}
export default Brands;

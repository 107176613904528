import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import THEME from "theme";

const Container = styled.div<{
  bgColor?: string;
  borderColor?: string;
  angle: number;
  offsetMargin?: number;
  tabOffsetTop?: number;
}>`
  position: absolute;
  width: 200%;
  margin-left: ${({ offsetMargin }) => offsetMargin || 0}%!important;
  left: 0;
  margin-right: 10%;
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.white};
  height: 104px;
  border-top: 3px solid
    ${({ theme, borderColor }) => borderColor || theme.colors.white};
  border-bottom: 3px solid
    ${({ theme, borderColor }) => borderColor || theme.colors.white};
  align-items: center;
  transform: rotate(${({ angle }) => angle || 0}deg);
  margin: 0;
  padding: 0;

  @media screen and (max-width: 1240px) {
    margin-left: ${({ offsetMargin, tabOffsetTop }) =>
      offsetMargin
        ? offsetMargin - (tabOffsetTop ? tabOffsetTop / 3 : 0)
        : 0}%!important;
    margin-top: ${({ tabOffsetTop }) => tabOffsetTop || 5}%!important;
  }

  @media screen and (max-width: 480px) {
    height: 44px;
  }
`;

const TapeWrapper = styled.div`
  height: 100%;
  float: left;
  position: relative;
  display: inline-block;
  width: max-content;

  &.cloned {
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

const TapeList = styled.ul`
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;

  & > li {
    float: left;
  }
`;

const TapeText = styled.li<{
  dotColor?: string;
  fontColor?: string;
}>`
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 32px;
  text-transform: uppercase;
  color: ${({ theme, fontColor }) => fontColor || theme.colors.primary}};

  &::before {
    font-size: 70px;
    content:"•";
    margin-left: 70px;
    margin-right: 70px;
    color: ${({ theme, dotColor }) => dotColor || theme.colors.primary};
  }
`;

export const TapeType = {
  TAPE_SOLANA: {
    text: "SOLANA",
    bgColor: THEME.colors.white,
    borderColor: THEME.colors.primary,
    fontColor: THEME.colors.primary,
    dotColor: THEME.colors.secondary,
  },
  TAPE_ALGORAND: {
    text: "ALGORAND",
    bgColor: THEME.colors.darkGray,
    borderColor: THEME.colors.white,
    fontColor: THEME.colors.white,
    dotColor: THEME.colors.secondary,
  },
  TAPE_ETHEREUM: {
    text: "ETHEREUM",
    bgColor: THEME.colors.secondary,
    borderColor: THEME.colors.primary,
    fontColor: THEME.colors.primary,
    dotColor: THEME.colors.primary,
  },
};

export interface TapeProps {
  type?: typeof TapeType.TAPE_SOLANA;
  angle?: number;
  offsetMargin?: number;
  tabOffsetTop?: number;
  rtl?: boolean;
}

function Tape({
  type = TapeType.TAPE_SOLANA,
  angle = 0,
  offsetMargin = 0,
  tabOffsetTop = 0,
  rtl = false,
}: TapeProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const cloneListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current && cloneListRef.current) {
      const listWidth = listRef.current.clientWidth;

      const infinite = gsap.timeline({ repeat: -1, paused: true });
      const time = 30;

      infinite
        .fromTo(
          listRef.current,
          time,
          { rotation: 0.01, x: 0 },
          { force3D: true, x: rtl ? listWidth : -listWidth, ease: "none" },
          0
        )
        .fromTo(
          cloneListRef.current,
          time,
          { rotation: 0.01, x: rtl ? -listWidth : listWidth },
          { force3D: true, x: 0, ease: "none" },
          0
        )
        .set(listRef.current, {
          force3D: true,
          rotation: 0.01,
          x: rtl ? -listWidth : listWidth,
        })
        .to(
          cloneListRef.current,
          time,
          {
            force3D: true,
            rotation: 0.01,
            x: rtl ? listWidth : -listWidth,
            ease: "none",
          },
          time
        )
        .to(
          listRef.current,
          time,
          { force3D: true, rotation: 0.01, x: 0, ease: "none" },
          time
        )
        .progress(1)
        .progress(0)
        .play();
    }
  }, [listRef, cloneListRef, rtl]);

  return (
    <Container
      bgColor={type.bgColor}
      borderColor={type.borderColor}
      angle={angle}
      offsetMargin={offsetMargin}
      tabOffsetTop={tabOffsetTop}
    >
      <TapeWrapper ref={listRef}>
        <TapeList>
          {[...Array(10)].map((_, index) => (
            <TapeText
              key={index}
              dotColor={type.dotColor}
              fontColor={type.fontColor}
            >
              {type.text}
            </TapeText>
          ))}
        </TapeList>
      </TapeWrapper>
      <TapeWrapper ref={cloneListRef} className="cloned">
        <TapeList>
          {[...Array(10)].map((_, index) => (
            <TapeText
              key={index}
              dotColor={type.dotColor}
              fontColor={type.fontColor}
            >
              {type.text}
            </TapeText>
          ))}
        </TapeList>
      </TapeWrapper>
    </Container>
  );
}

export default Tape;

import styled from "styled-components";

const SvgContainer = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})<{
  size?: number;
  hoverColor?: string;
}>`
  height: ${({ size }) => (size ? `${size}px` : "100%")};
  & svg {
    height: ${({ size }) => (size ? `${size}px` : "100%")};
    width: ${({ size }) => (size ? `${size}px` : "100%")};
    &:hover {
      rect:first-child {
        fill: ${({ hoverColor }) => hoverColor};
      }
    }
  }
`;

type SocialIconProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  href: string;
  hoverColor: string;
  size: number;
};

export default function SocialIcon(props: SocialIconProps) {
  const { icon: Icon, href, hoverColor, size } = props;
  return (
    <SvgContainer href={href} size={size} hoverColor={hoverColor}>
      <Icon />
    </SvgContainer>
  );
}
